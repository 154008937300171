import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

// Custom Divider with gradient
const GradientDivider = styled('div')(({ theme }) => ({
  height: '2px',
  background: 'linear-gradient(to right, #FF6A00, black)',
  border: 'none',
  margin: theme.spacing(2, 0),
}));

const FAQSection = () => {
  const faqs = [
    {
      question: 'O que é clonagem de voz?',
      answer:
        'Clonagem de voz é a tecnologia que permite criar uma réplica digital da voz humana, utilizada em diversas aplicações, como audiolivros e assistentes virtuais.',
    },
    {
      question: 'Como funciona a sincronização labial?',
      answer:
        'A sincronização labial alinha o movimento dos lábios de um personagem ou vídeo com o áudio correspondente, criando uma experiência visualmente coerente.',
    },
    {
      question: 'Quais ferramentas posso usar para clonagem de voz?',
      answer:
        'Existem várias ferramentas disponíveis, como Eleven Labs e Descript, que oferecem funcionalidades de clonagem de voz e edição de áudio.',
    },
    {
      question: 'Como posso melhorar meus criativos com essas tecnologias?',
      answer:
        'Utilizando clonagem de voz e sincronização labial, você pode criar vídeos envolventes que capturam a atenção do público e melhoram a conversão.',
    },
    {
      question: 'Qual é o tempo de entrega para vídeos criados com essas tecnologias?',
      answer:
        'O tempo de entrega varia de 2 a 5 dias úteis, dependendo da complexidade do projeto e da demanda.',
    },
  ];

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: 'auto',
        padding: '40px 20px',
        backgroundColor: '#000', // Fundo preto
        color: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Perguntas Frequentes
      </Typography>
      <GradientDivider />

      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            backgroundColor: '#111', // Fundo escuro para cada item do Accordion
            border: '1px solid #FF6A00', // Borda laranja
            marginBottom: '10px',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FF6A00' }} />} // Ícone expandir laranja
            aria-controls={`faq-content-${index}`}
            id={`faq-header-${index}`}
          >
            <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: 'white' }}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
