import React from 'react';
import Header from '../components/Header';
import VideoSection from '../components/VideoSection';
import { Container, Grid } from '@mui/material';
import FAQSection from '../components/FAQSection';
import Footer from '../components/Footer';
import OfertaBootcampPremium from '../components/OfertaBootcamp';
import ReasonSection from '../components/ReasonSection';
import LastDoubtsSection from '../components/LastDoubtsSection';
export default function BootcampPage() {
  return (
    <Container 
      sx={{ 
        backgroundColor: '#000000', 
        minHeight: '100vh', 
        color: 'white', 
        paddingTop: '40px' 
      }}
    >
      <Header />

      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={12}>
          <VideoSection />
        </Grid>

   
      </Grid>
      <ReasonSection/>
      <OfertaBootcampPremium />
      <FAQSection />
      <Footer />
    </Container>
  );
}
