import React from 'react';
import { Box, Typography } from '@mui/material';
//...

export default function Header() {


  return (
    <Box sx={{ textAlign: 'center', paddingBottom: '50px', backgroundColor: 'black', padding: '60px 20px' }}>
      {/* Nome do Produto */}
      <Typography 
        variant="h5" 
        sx={{ color: '#FF6A00', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}
      >
O Futuro do Conteúdo Está Aqui: Não Deixe Essa Onda Passar por Você!
      </Typography>

      {/* Headline chamativa */}
      <Typography 
        variant="h3" 
        sx={{ 
          fontFamily: 'Montserrat, sans-serif', 
          fontWeight: 'bold', 
          color: 'white',
          mb: 2
        }}
      >
Quem Descobriu o Poder da Clonagem de Voz e Sincronização Labial Já Está Aproveitando Resultados Incríveis COM O ROI NO TALO — Não Perca!
     </Typography>

      {/* Subheadline */}
      <Typography 
        variant="h5" 
        sx={{ 
          fontFamily: 'Montserrat, sans-serif', 
          color: '#FF6A00',
          fontWeight: '500',
          mb: 4
        }}
      >
Coloque os Famosos ao Seu Lado e Veja Seus Produtos Vendendo Sozinhos!     </Typography>


    </Box>
  );
}
