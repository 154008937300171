import React from 'react';
import { Box } from '@mui/material';
import AudioPlayerWithButton from './AudioPlayer';
export default function VideoSection() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        color: 'white',
        textAlign: 'center',
        padding: 0, // Removendo o padding
        width: '100%',
        minHeight: { xs: '60vh', md: '100vh' }, // Altura mínima para dispositivos móveis
      }}
    >
      <Box
        sx={{
          border: '5px solid #FF6A00',
          borderRadius: '10px',
          overflow: 'hidden',
          width: { xs: '100%', sm: '90%', md: '80%', lg: '70%', xl: '65%' },
          maxWidth: '1200px',
          position: 'relative',
          paddingBottom: '49.84%', // Para manter a proporção do vídeo
          height: 0,
          mb: 2, // Margem inferior reduzida para dispositivos móveis
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/1023833955?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
          title="CRIATIVO PORTAL IA"
        ></iframe>
      </Box>
        <AudioPlayerWithButton/>
     
    </Box>
  );
}
