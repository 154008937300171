import React from 'react'; 
import { Typography, Divider, Box, Card, Grid } from '@mui/material'; 
import { styled } from '@mui/system'; 
import SchoolIcon from '@mui/icons-material/School'; 
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice'; // Ícone para fácil acesso
import AppsIcon from '@mui/icons-material/Apps'; // Ícone para múltiplos aplicativos
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Ícone para ROI
import ControlCameraIcon from '@mui/icons-material/ControlCamera'; // Ícone para controle total
import SpeedIcon from '@mui/icons-material/Speed'; // Ícone para eficiência

// Estilo para o Divider com gradiente laranja e preto
const GradientDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  background: 'linear-gradient(to right, #FF6A00, black)',
  border: 'none',
}));

// Estilo para o Card com fundo preto e bordas arredondadas
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#1a1a1a',
  color: '#fff',
  margin: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: '15px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.7)',
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

// Componente para renderizar o conteúdo dos cards
const CardContentStyled = ({ title, description, icon }) => (
  <StyledCard>
    {icon}
    <Typography variant="h6" gutterBottom sx={{ mt: 1 }}>{title}</Typography>
    <Typography variant="body2">{description}</Typography>
  </StyledCard>
);

const ReasonSection = () => {
  return (
    <Box sx={{ maxWidth: '1200px', margin: 'auto', textAlign: 'center', padding: '40px 20px' }}>
      {/* Título da seção */}
      <Typography variant="h4" align="center" sx={{ mb: 2, fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: 'bold' }}>
        NUNCA MAIS FAÇA O L PARA O ROI 
      </Typography>
      
      <Typography variant="h5" align="center" sx={{ mb: 2, fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: 'italic' }}>
      Diga adeus aos testes frustrantes! Aprenda a criar anúncios que realmente geram ROI e escapam do ciclo de tentativa e erro. Criativos eficientes são essenciais para otimizar seu desempenho, reduzindo o custo por aquisição (CPA) e melhorando métricas como CPC e CPS. Aumente seus lucros e veja sua operação decolar para novos patamares!






</Typography>

      <GradientDivider />

      {/* Grid com os cards */}
      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Fácil Acesso"
            description="Receba as informações diretamente no seu e-mail!"
            icon={<LocalPostOfficeIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Múltiplos Aplicativos"
            description="Descubra os melhores programas e aplicativos IA para alavancar seu negócio."
            icon={<AppsIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Didático"
            description="Aprenda de forma intuitiva, sem complicações."
            icon={<SchoolIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="ROI de 10x"
            description="Aumente seus lucros e escale como os grandes do mercado."
            icon={<MonetizationOnIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Controle Total"
            description="Tenha domínio completo sobre seus criativos e campanhas."
            icon={<ControlCameraIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Eficiência"
            description="Teste e escale suas campanhas 10x mais rápido!"
            icon={<SpeedIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReasonSection;
