import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';

const PromocaoAcabaHoje = () => {
  // Função para calcular a data de hoje
  const getTodayDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Box>
      {/* Barra de topo com estilização responsiva */}
      <AppBar 
        position="static" 
        sx={{ 
          backgroundColor: '#ff0000',
          minHeight: { xs: '40px', md: '50px' } // Altura mínima ajustada para dispositivos menores
        }}
      >
        <Toolbar 
          sx={{ 
            justifyContent: 'center', 
            minHeight: { xs: '40px', md: '50px' }, // Altura ajustada do Toolbar
            padding: { xs: '0px', md: '0px' } // Reduzindo padding para dar mais compacidade
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ 
              color: '#fff', 
              fontWeight: 500,  // Peso da fonte
              textAlign: 'center', 
              fontSize: { xs: '1rem', md: '1.5rem' } // Responsivo para tamanhos menores
            }}
          >
            Última chance! Desconto exclusivo termina 
            <span style={{ color: '#FFD700', fontWeight: 'bold' }}> HOJE</span>, dia {getTodayDate()}. Não perca!
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default PromocaoAcabaHoje;
