// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade';
import WhatsAppButton from './components/WhatsAppButton';
import TermosDeUso from './pages/TermoDeUso';
import BootcampPage from './pages/BootcampPage';
import PromocaoAcabaHoje from './components/PromocaoAcabaHoje';
const App = () => {
  return (
    <div className='background'>
      <PromocaoAcabaHoje/>
      <Router>
        <Routes>
          <Route path="/" element={<BootcampPage />} />
          <Route path='/termos' element={<TermosDeUso/> } />
          <Route path='/privacidade' element={<PoliticaDePrivacidade/>} />

        </Routes>
      </Router>

        <WhatsAppButton/>
    </div>
  );
};

// Combinação dos componentes Home e ProjectList


export default App;
