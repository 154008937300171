import React, { useState, useRef } from 'react';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import audioFile from '../audio/audio.mp3'; // Ajuste o caminho conforme necessário

const AudioPlayerWithButton = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null); // Usar useRef corretamente

  const handlePlay = () => {
    setIsPlaying(true);
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Erro ao tentar reproduzir o áudio:", error);
      });
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px', overflow: 'hidden' }}>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        animate={{ opacity: [0.8, 1], scale: [1, 1.05] }}
        transition={{
          duration: 0.8,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: '#FF6A00', // Nova cor do botão
            color: '#fff',
            padding: '12px 24px',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            borderRadius: '30px', // Bordas mais arredondadas
            boxShadow: '0px 4px 15px rgba(255, 106, 0, 0.5)',
            '&:hover': {
              backgroundColor: '#e65c00', // Cor de hover um pouco mais escura
            },
            // Responsividade
            '@media (max-width: 600px)': {
              padding: '10px 20px',
              fontSize: '1rem',
            },
          }}
          onClick={handlePlay}
        >
          Ouça o ROI do Seu Criativo!
        </Button>
      </motion.div>
      
      {isPlaying && (
        <audio ref={audioRef} src={audioFile} loop style={{ display: 'none' }} />
      )}
    </div>
  );
};

export default AudioPlayerWithButton;
