import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const TermosDeUso = () => {
  return (
    <Box
      sx={{
        maxWidth: '800px',
        margin: 'auto',
        padding: '40px 20px',
        backgroundColor: '#000', // Fundo preto
        color: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Termos de Uso
      </Typography>

      <Typography variant="body1" gutterBottom>
        Estes Termos de Uso regulam o acesso e a utilização do Bootcamp Python com Projetos Reais. Ao utilizar nossos serviços, você concorda em cumprir e estar sujeito a estes termos.
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Aceitação dos Termos
      </Typography>
      <Typography variant="body1" gutterBottom>
        Ao se inscrever e utilizar o nosso Bootcamp, você aceita estes termos em sua totalidade.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Propriedade Intelectual
      </Typography>
      <Typography variant="body1" gutterBottom>
        Todo o conteúdo do Bootcamp, incluindo materiais, vídeos, e documentação, é protegido por direitos autorais e não pode ser reproduzido sem autorização prévia.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Proteção de Dados
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nossos serviços estão em conformidade com a Lei Geral de Proteção de Dados (LGPD) no Brasil e o Regulamento Geral sobre a Proteção de Dados (GDPR) na União Europeia. Coletamos e processamos dados pessoais de acordo com nossa <Link href="/privacidade" color="inherit">Política de Privacidade</Link>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Limitação de Responsabilidade
      </Typography>
      <Typography variant="body1" gutterBottom>
        Não nos responsabilizamos por quaisquer danos diretos ou indiretos resultantes do uso ou da incapacidade de uso do Bootcamp.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Alterações aos Termos
      </Typography>
      <Typography variant="body1" gutterBottom>
        Reservamo-nos o direito de alterar estes termos a qualquer momento. É sua responsabilidade revisar regularmente os termos atualizados.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Contato
      </Typography>
      <Typography variant="body1" gutterBottom>
        Para dúvidas sobre estes termos, entre em contato conosco através do e-mail: nomadeprogramador@gmail.com
      </Typography>

      <Typography variant="body1" gutterBottom>
        Última atualização: {new Date().toLocaleDateString()}
      </Typography>
    </Box>
  );
};

export default TermosDeUso;
