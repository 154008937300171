import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { motion } from 'framer-motion';

const OfertaProduto = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #000 30%, #1a1a1a 90%)',
        color: '#fff',
        padding: '60px 40px',
        borderRadius: '16px',
        textAlign: 'center',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
      }}
    >
      {/* Título da Oferta */}
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            color: '#FF6A00',
            fontWeight: 'bold',
            letterSpacing: '2px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          OFERTA EXCLUSIVA PARA VOCÊ!
        </Typography>
      </motion.div>

      {/* Imagem do Produto */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3, ease: 'easeOut' }}
      >
       
      </motion.div>

      {/* Preço e Comparação */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4, ease: 'easeOut' }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: '#FF6A00',
            fontSize: '28px',
            fontWeight: '500',
            marginTop: '20px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          <strong>Preço Especial:</strong> De <span style={{ textDecoration: 'line-through', color: '#fff' }}>R$ 299,90</span> por apenas <span style={{ color: '#FF6A00', fontWeight: 'bold' }}>R$ 59,90 </span>!
          <br />
            COLOQUE O ROI ATÉ O TALO 
        </Typography>
      </motion.div>

      {/* Divisor */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5, ease: 'easeOut' }}
      >
        <Divider
          sx={{
            backgroundColor: '#FF6A00',
            height: '3px',
            margin: '30px 0',
            width: '80%',
            mx: 'auto',
          }}
        />
      </motion.div>

      {/* Descrição da Oferta */}
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4, ease: 'easeOut' }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: '20px',
            margin: '20px 0',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
O oceano da IA está aberto, e você precisa surfar essa onda antes que ela passe! Não deixe para depois: as IAs vão dominar o mundo, e quem chega primeiro bebe a água mais pura. Se esperar, só vai encontrar as sobras. Aproveite o momento e lidere essa revolução!        </Typography>
      </motion.div>

      {/* Botão de Chamada para Ação */}
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF6A00',
            color: '#fff',
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '15px 30px',
            borderRadius: '50px',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#e65b00',
            },
          }}
          href="https://pay.kiwify.com.br/BReCxEm"
        >
          EU QUERO ROI DE 10X
        </Button>
      </motion.div>

      {/* Garantia */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.7, ease: 'easeOut' }}
      >
        <Typography
          variant="body2"
          sx={{
            marginTop: '30px',
            fontSize: '16px',
            fontFamily: 'Poppins, sans-serif',
            opacity: 0.8,
          }}
        >
        </Typography>
      </motion.div>
    </Box>
  );
};

export default OfertaProduto;
